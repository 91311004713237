import { Box, InputLabel, Stack, TextField } from '@mui/material';
import { ComponentProps, FC } from 'react';
import { Controller } from 'react-hook-form';

type Props = ComponentProps<typeof TextField> & {
    name: string;
    inline?: boolean;
};

export const ControlledInput: FC<Props> = ({ name, label, inline, ...textFieldProps }) => {
    return (
        <Controller
            name={name}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                    {inline ? (
                        <Stack direction="row" justifyContent="flex-start" spacing={0}>
                            <Box sx={{ marginRight: -4 }}>
                                <InputLabel htmlFor={`${name}-field`} sx={{ paddingTop: 2, top: 0 }}>
                                    {label}
                                </InputLabel>
                            </Box>
                            <TextField
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                id={`${name}-field`}
                                {...textFieldProps}
                            />
                        </Stack>
                    ) : (
                        <TextField
                            value={value}
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                            id={`${name}-field`}
                            {...textFieldProps}
                            label={label}
                            inputProps={
                                textFieldProps.multiline && window.carity.environment.theme.name === 'antwerp'
                                    ? {
                                          ...textFieldProps.inputProps,
                                          style: {
                                              padding: 24,
                                          },
                                      }
                                    : textFieldProps.inputProps
                            }
                        />
                    )}
                </>
            )}
        />
    );
};
