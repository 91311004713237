import { Grid } from '@mui/material';
import { Children, ComponentProps, FC, ReactNode } from 'react';

interface Props extends ComponentProps<typeof Grid> {
    children?: ReactNode;
    containerProps?: ComponentProps<typeof Grid>;
}

export const FormGrid: FC<Props> = ({ children, containerProps, ...props }) => {
    return (
        <Grid container spacing={2} {...containerProps}>
            {Children.map(children, (child, key) =>
                child ? (
                    <Grid item key={key} {...props}>
                        {child}
                    </Grid>
                ) : (
                    <></>
                ),
            )}
        </Grid>
    );
};
