import { BaseClient, rentingApi } from '../../shared';
import { IProject } from '../models/project.model';

class ProjectsClient extends BaseClient<IProject, IProject> {
    constructor() {
        super(rentingApi, '/projects');
    }
}

export const projectsClient = new ProjectsClient();
