import { FormControl, FormHelperText, Input, InputLabel, OutlinedInput, Select, Stack } from '@mui/material';
import { ComponentProps, FC } from 'react';
import { Controller } from 'react-hook-form';
import * as React from 'react';

type Props = ComponentProps<typeof Select> & {
    name: string;
    label?: string;
    minWidth?: number;
    items?: string[];
    inline?: boolean;
};

export const ControlledSelect: FC<Props> = ({
    name,
    required,
    label,
    children,
    minWidth = 195,
    items = [],
    inline,
    ...selectProps
}) => {
    const theme = window.carity.environment.theme;
    const antwerpTheme = theme.name === 'antwerp';

    const handleOnChangeMultipleSelect = (e: any) => {
        const value = e.target.value;

        if (value.includes('all')) {
            const areAllItemsSelected = value.length === items.length + 1;
            e.target.value = areAllItemsSelected ? [] : items;
        }

        return e;
    };

    return (
        <Controller
            name={name}
            defaultValue={selectProps.multiple ? [] : ''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Stack direction="row" spacing={0} alignItems="center">
                    {antwerpTheme && inline && label && (
                        <InputLabel
                            sx={{ pt: 1.5, top: 0 }}
                            id={name}
                            required={required}
                            disabled={selectProps.disabled}
                        >
                            {label}
                        </InputLabel>
                    )}
                    <FormControl sx={{ minWidth, width: inline ? 'auto' : '100%' }} error={!!error}>
                        {(!antwerpTheme || (!inline && label)) && (
                            <InputLabel id={name} required={required} disabled={selectProps.disabled}>
                                {label}
                            </InputLabel>
                        )}
                        <Select
                            sx={{
                                minWidth,
                                '.MuiSelect-select.MuiSelect-multiple': { height: 'auto', pb: 1 },
                            }}
                            onChange={
                                !selectProps.multiple ? onChange : (e) => onChange(handleOnChangeMultipleSelect(e))
                            }
                            value={value}
                            id={`${name}-select`}
                            required={required}
                            input={antwerpTheme ? <Input /> : <OutlinedInput notched label={label} />}
                            label={label}
                            renderValue={selectProps.renderValue ? selectProps.renderValue : undefined}
                            size={inline ? 'small' : undefined}
                            {...selectProps}
                        >
                            {children}
                        </Select>
                        {error && (
                            <FormHelperText id={`${name}-field-helper-text`}>
                                {error ? error.message : null}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Stack>
            )}
        />
    );
};
