import { DeleteOutline, Edit } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
    Page,
    RemoveModal,
    SortOrder,
    StatusCode,
    Table,
    useDeletePropertyType,
    usePropertyTypeList,
    useQueryParams,
} from '../../../shared';
import { InUseWarning } from '../../../shared/components/in-use-warning/in-use-warning.component';

export const PropertyTypesPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { page, setPage, pageSize, setPageSize, sort, setSort } = useQueryParams({
        defaultSort: [{ field: 'code', sort: 'asc' }],
    });

    const { data: propertyTypes, isLoading } = usePropertyTypeList({
        page,
        pageSize,
        sortBy: sort[0]?.field,
        sortOrder: sort[0]?.sort?.toUpperCase() as SortOrder,
    });
    const { mutateAsync: deletePropertyType } = useDeletePropertyType();

    const [showPropertyTypeInUse, setShowPropertyTypeInUse] = useState(false);

    const onDelete = useCallback(
        async (id: string) => {
            try {
                await deletePropertyType(id);
            } catch (e: any) {
                if (e.response.status === StatusCode.CONFLICT) {
                    return setShowPropertyTypeInUse(true);
                }
            }
        },
        [deletePropertyType],
    );

    const columns: GridColDef[] = [
        {
            field: 'code',
            headerName: t('propertyTypeCode'),
            minWidth: 150,
            flex: 1,
            sortable: true,
        },
        {
            field: 'name',
            headerName: t('propertyTypeName'),
            minWidth: 150,
            flex: 1,
            sortable: true,
        },
        {
            field: ' ',
            headerName: '',
            width: 120,
            align: 'right',
            sortable: false,
            renderCell: (params: GridCellParams) => (
                <>
                    <IconButton component={Link} to={`/admin/property-types/${params.row.id}`} size="large">
                        <Edit />
                    </IconButton>
                    <RemoveModal
                        handleDelete={() => onDelete(params.row.id)}
                        button={
                            <IconButton aria-label="delete" size="large">
                                <DeleteOutline />
                            </IconButton>
                        }
                        title={t('propertyTypeDeleteWarningTitle')}
                        text={t('propertyTypeDeleteWarningText')}
                    />
                </>
            ),
        },
    ];

    return (
        <Page
            title={t('propertyTypes')}
            actions={[
                <Button component={Link} to="/admin/property-types/new" variant="contained">
                    {t('newPropertyType')}
                </Button>,
            ]}
        >
            <Table
                rows={propertyTypes?.data || []}
                columns={columns}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                rowCount={propertyTypes?.pagination.size || 0}
                loading={isLoading}
                sortModel={sort}
                onSortModelChange={setSort}
                onRowClick={(row) => navigate(`/admin/property-types/${row.id}/edit`)}
            />

            <InUseWarning
                open={showPropertyTypeInUse}
                onClose={() => setShowPropertyTypeInUse(false)}
                title={t('propertyTypeInUseTitle')}
                text={t('propertyTypeInUseDescription')}
            />
        </Page>
    );
};
