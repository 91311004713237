import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { boolean, number, object, string } from 'yup';

export function usePropertySchema() {
    const { t } = useTranslation();
    return useMemo(
        () =>
            object().shape({
                name: string().required(t('requiredField')),
                typeId: string().required(t('requiredField')),
                projectId: string().required(t('requiredField')),
                address: object().shape({
                    street: string().required(t('requiredField')),
                    number: string().required(t('requiredField')),
                    box: string().nullable().optional(),
                    zip: string().required(t('requiredField')),
                    region: string().nullable().optional(),
                    city: string().required(t('requiredField')),
                    country: string().required(t('requiredField')),
                }),
                amountOfBedrooms: number().min(0, t('negativeNumber')).typeError(t('invalidNumber')).required(),
                area: number().min(0, t('negativeNumber')).typeError(t('invalidNumber')).required(),
                monthlyRent: number().min(0, t('negativeNumber')).typeError(t('invalidNumber')).required(),
                monthlyExtraCost: number().min(0, t('negativeNumber')).typeError(t('invalidNumber')).required(),
                rentYear: string()
                    .required()
                    .test('isNumber', t('invalidNumber'), (val) => !isNaN(parseInt(val))),
                hasElevator: boolean().required(t('requiredField')),
                comment: string(),
            }),
        [t],
    );
}
