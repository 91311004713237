import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlledCheckbox, ControlledInput, FormGrid } from '../../../shared';
import { Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

export const PropertyEditExtras: FC = () => {
    const { t } = useTranslation();
    const form = useFormContext();

    return (
        <>
            <Typography variant="subtitle2">{t('propertyExtras')}</Typography>
            <FormGrid xs={12} md={'auto'} sx={{ '&.MuiGrid-item ': { pt: 0 } }}>
                <ControlledCheckbox control={form.control} name="hasElevator" label={t('propertyHasElevator')} />
            </FormGrid>
            <ControlledInput name="comment" label={t('comment')} multiline rows={2} />
        </>
    );
};
