import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlledInput, ControlledSelect, FormGrid, IProperty } from '../../../shared';
import { MenuItem, Typography } from '@mui/material';
import { getYear } from 'date-fns';

export const PropertyEditRent: FC<{ property?: IProperty }> = ({ property }) => {
    const { t } = useTranslation();

    const rentYearOptions = useMemo(() => {
        const currentYear = getYear(new Date());

        const previouslyFilledInYear = property?.rentYear.toString() || '';
        const uniqueValues = [
            ...new Set([
                previouslyFilledInYear,
                currentYear.toString(),
                (currentYear - 1).toString(),
                (currentYear - 2).toString(),
            ]),
        ].filter((year) => !!year);

        return uniqueValues.sort();
    }, [property]);

    return (
        <>
            <Typography variant="subtitle2">{t('propertyRent')}</Typography>
            <FormGrid xs={12} md={'auto'}>
                <ControlledInput name="monthlyRent" label={t('propertyMonthlyRent')} required />
                <ControlledInput name="monthlyExtraCost" label={t('propertyMonthlyExtraCost')} required />
                <ControlledSelect name="rentYear" label={t('rentYear')} required>
                    {rentYearOptions.map((year) => (
                        <MenuItem key={year} value={year}>
                            {year}
                        </MenuItem>
                    ))}
                </ControlledSelect>
            </FormGrid>
        </>
    );
};
