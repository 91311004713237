import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlledInput, ControlledNumberInput, FormGrid } from '../../../shared';
import { Typography } from '@mui/material';

export const PropertyEditOccupation: FC = () => {
    const { t } = useTranslation();

    return (
        <>
            <Typography variant="subtitle2">{t('propertyOccupation')}</Typography>
            <FormGrid xs={12} md={'auto'}>
                <ControlledInput name="amountOfBedrooms" label={t('propertyAmountOfBedrooms')} required />
                <ControlledNumberInput name={`area`} label={t('propertyArea')} required />
            </FormGrid>
        </>
    );
};
