export enum RentalRequestAssetType {
    RESIDENTIAL_HISTORY_DOCUMENT = 'RESIDENTIAL_HISTORY_DOCUMENT',
    TAX_BILL_DOCUMENT = 'TAX_BILL_DOCUMENT',
    MONTHLY_INCOME_DOCUMENT = 'MONTHLY_INCOME_DOCUMENT',
    LIVING_WAGE_DOCUMENT = 'LIVING_WAGE_DOCUMENT',
    DISABILITY_INCOME_DOCUMENT = 'DISABILITY_INCOME_DOCUMENT',
    PREGNANCY_DOCUMENT = 'PREGNANCY_DOCUMENT',
    CHILD_SUPPORT_DOCUMENT = 'CHILD_SUPPORT_DOCUMENT',
    RESIDENTIAL_RELATION_DOCUMENT = 'RESIDENTIAL_RELATION_DOCUMENT',
    WORK_STUDY_RELATION_DOCUMENT = 'WORK_STUDY_RELATION_DOCUMENT',
    ADDITIONAL_DOCUMENT = 'ADDITIONAL_DOCUMENT',
    BUDGET_RENTING_DOCUMENT = 'BUDGET_RENTING_DOCUMENT',
    RENT_SUBSIDY_DOCUMENT = 'RENT_SUBSIDY_DOCUMENT',
}
