import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlledInput, ControlledSelect, FormGrid, usePropertyTypeList } from '../../../shared';
import { MenuItem, Typography } from '@mui/material';
import { useProjectList } from '../../../admin/hooks/projects.hook';

export const PropertyEditGeneral: FC = () => {
    const { t } = useTranslation();
    const { data: propertyTypes } = usePropertyTypeList({ page: 1, pageSize: 1000 });
    const { data: projects } = useProjectList({ page: 1, pageSize: 1000 });

    return (
        <>
            <Typography variant="subtitle2">{t('property')}</Typography>
            <FormGrid xs={12} md={'auto'}>
                <ControlledSelect name="typeId" label={t('propertyPropertyType')} required>
                    {propertyTypes?.data?.map((propertyType) => (
                        <MenuItem key={propertyType.id} value={propertyType.id}>
                            {propertyType.name}
                        </MenuItem>
                    ))}
                </ControlledSelect>
                <ControlledInput name="name" label={t('propertyName')} required />
                <ControlledSelect name="projectId" label={t('project')} required>
                    {projects?.data?.map((project) => (
                        <MenuItem key={project.id} value={project.id}>
                            {project.name}
                        </MenuItem>
                    ))}
                </ControlledSelect>
            </FormGrid>
        </>
    );
};
