import { QueryKeys, useDelete, useItem, UseItemParams, useList, UseListParams, useSave } from '../../shared';
import { IProject } from '../models/project.model';
import { projectsClient } from '../clients/projects.client';

const client = projectsClient;
const listKey = QueryKeys.Projects;
const itemKey = QueryKeys.Project;

export function useProjectList(...args: UseListParams<IProject>) {
    return useList(client, listKey, ...args);
}

export function useProject(...args: UseItemParams<IProject>) {
    return useItem(client, itemKey, ...args);
}

export function useSaveProject() {
    return useSave(client, listKey, itemKey);
}

export function useDeleteProject() {
    return useDelete(client, listKey);
}
