import { IProperty, IPropertyForm, ISaveProperty } from '../../shared';

export const propertyToFormMapper = (property: IProperty): IPropertyForm => {
    return {
        ...property,
        typeId: property?.type?.id,
        projectId: property?.project?.id,
        address: {
            ...property?.address,
            box: property?.address?.box || '',
            region: property?.address?.region || '',
        },
        rentYear: property.rentYear.toString(),
        comment: property.comment || '',
    };
};

export const propertyFromFormMapper = (property: IPropertyForm): ISaveProperty => {
    return {
        ...property,
        address: {
            ...property?.address,
            region: property?.address?.region || null,
        },
        rentYear: parseInt(property.rentYear, 10),
    };
};
