import { alpha, Components, createTheme, Theme } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { createBaseTheme } from './base.theme';
import { ITheme } from '../../shared';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { getComponents, getPalette, getTypography } from '../utils';

export const createAntwerpTheme = (theme: ITheme): Theme => {
    const palette = getPalette(theme.color);
    const antwerpTheme = createTheme({ palette });
    const typography = getTypography(antwerpTheme, typographyOverride());
    const components = getComponents(antwerpTheme, componentOverride(antwerpTheme));

    return deepmerge(
        createBaseTheme(theme),
        createTheme(antwerpTheme, { shape: { borderRadius: 0 }, palette, typography, components }),
    );
};

function typographyOverride(): TypographyOptions {
    return {
        h2: { fontFamily: '"Antwerpen Regular", sans-serif' },
        subtitle1: { fontFamily: '"Antwerpen Regular", sans-serif' },
        subtitle2: { fontFamily: '"Antwerpen Regular", sans-serif' },
    };
}

function componentOverride({ palette, breakpoints }: Theme): Components {
    return {
        MuiAlert: {
            styleOverrides: {
                root: {
                    borderRadius: '0 !important',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    paddingLeft: 24,
                    paddingRight: 24,
                    height: 40,
                    [':focus']: {
                        outlineWidth: '2px',
                        outlineStyle: 'solid',
                        outlineColor: palette.primary.main,
                        outlineOffset: '1px',
                    },
                },
            },
            defaultProps: {
                disableElevation: true,
                disableRipple: true,
            },
        },
        MuiChip: {
            defaultProps: {
                variant: 'outlined',
                color: 'primary',
            },
            styleOverrides: {
                root: {
                    borderWidth: 2,
                    borderRadius: 0,
                },
            },
        },
        MuiFormControl: {
            defaultProps: {
                variant: 'standard',
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: '#141414',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: 20,
                    color: '#141414',
                    top: -5,
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    '&::after': { display: 'none' },
                    '&::before': { display: 'none' },
                },
                input: {
                    height: 40,
                    boxSizing: 'border-box',
                    padding: '0 1.5rem',
                    '&::placeholder': {
                        opacity: 0.6,
                        color: '#141414',
                    },
                    '&:focus': {
                        borderColor: palette.primary.main,
                        boxShadow: `${alpha(palette.primary.main, 0.25)} 0 0 0 2px`,
                    },
                },
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                positionStart: { paddingLeft: '15px' },
                positionEnd: { paddingRight: '15px' },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    padding: 0,
                    height: 'inherit' || 40,
                    borderStyle: 'solid',
                    borderColor: '#6F6F6F',
                    borderWidth: 1,
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderStyle: 'solid',
                    borderColor: '#6F6F6F',
                    borderWidth: 1,
                    height: 40,
                    '&.Mui-focused': {
                        borderStyle: 'none',
                        borderColor: palette.primary.main,
                        boxShadow: `${alpha(palette.primary.main, 0.25)} 0 0 0 2px`,
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    height: 40,
                    paddingTop: 8,
                    paddingRight: '36px !important',
                    textAlign: 'left',

                    '&.MuiTablePagination-select': {
                        height: 'auto',
                    },
                },
                icon: {
                    right: 12,
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&:nth-of-type(odd)': {
                        backgroundColor: palette.background.default,
                    },
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: 'standard',
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    [breakpoints.up('xs')]: {
                        paddingLeft: 0,
                        ['.drawer-button']: { marginLeft: 8 },
                    },
                    '& .app-logo': {
                        maxHeight: '55px',
                        marginRight: 12,
                    },
                },
            },
        },
    };
}
