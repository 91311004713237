import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { number, object, string } from 'yup';

export function useProjectSchema() {
    const { t } = useTranslation();
    return useMemo(
        () =>
            object().shape({
                name: string().required(t('requiredField')),
                propertyDiscountOneBedroom: number()
                    .transform((value) => (isNaN(value) ? null : value))
                    .typeError(t('invalidNumber'))
                    .min(0, t('negativeNumber'))
                    .required(t('requiredField')),
                propertyDiscountMultipleBedroomsSingleParent: number()
                    .transform((value) => (isNaN(value) ? null : value))
                    .typeError(t('invalidNumber'))
                    .min(0, t('negativeNumber'))
                    .required(t('requiredField')),
                propertyDiscountMultipleBedroomsOther: number()
                    .transform((value) => (isNaN(value) ? null : value))
                    .typeError(t('invalidNumber'))
                    .min(0, t('negativeNumber'))
                    .required(t('requiredField')),
            }),
        [t],
    );
}
