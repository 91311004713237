import { MenuItem, Stack } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    ControlledSelect,
    IPropertyQuery,
    Options,
    OptionsToggle,
    PropertyStatus,
    usePropertyTypeList,
} from '../../shared';
import { checkDifferentFilterAndFormValues } from '../../shared/utils/check-different-filter-and-form-values.util';
import { useProjectList } from '../../admin/hooks/projects.hook';

interface Props {
    filter: Record<string, any>;
    onChange: (filter: IPropertyQuery & { options: Options }) => void;
}

export const PropertyFilter: FC<Props> = ({ filter, onChange }) => {
    const { t } = useTranslation();
    const form = useForm<IPropertyQuery>({ mode: 'onChange' });

    const { data: projects, isLoading: isProjectsLoading } = useProjectList({ page: 1, pageSize: 100 });
    const { data: propertyTypes, isLoading: isPropertyTypesLoading } = usePropertyTypeList({
        page: 1,
        pageSize: 100,
    });

    const defaultOptions = useMemo(
        () => ({
            filter: { type: 'title', label: t('filter') },
            propertyTypeId: { label: t('propertyType'), active: false },
            projectId: { label: t('project'), active: false },
            status: { label: t('status'), active: false },
        }),
        [t],
    );
    const filterOptions: Options = useMemo(() => {
        return filter.options || defaultOptions;
    }, [defaultOptions, filter.options]);

    const setOptions = useCallback((options: Options) => onChange({ ...filter, options }), [filter, onChange]);

    const formValues = form.watch();

    useEffect(() => {
        form.reset(filter);
    }, [form, filter]);

    useEffect(() => {
        const { propertyTypeId, projectId, status } = filterOptions;
        if (!propertyTypeId.active) form.setValue('propertyTypeId', undefined);
        if (!projectId.active) form.setValue('projectId', undefined);
        if (!status.active) form.setValue('status', undefined);
    }, [form, filterOptions]);

    useEffect(() => {
        if (!!Object.keys(formValues).length && checkDifferentFilterAndFormValues(formValues, filter)) {
            onChange({ ...formValues, options: filterOptions });
        }
    }, [formValues, filter, filterOptions, onChange]);

    return (
        <FormProvider {...form}>
            <Stack direction="row" spacing={1} pr={1}>
                <OptionsToggle options={filterOptions} onChange={setOptions} />
                {filterOptions.propertyTypeId.active && !isPropertyTypesLoading && (
                    <ControlledSelect
                        name={`propertyTypeId`}
                        label={t('propertyType')}
                        items={propertyTypes?.data.map((property) => property.name) || []}
                        size="small"
                    >
                        <MenuItem value={undefined}>{t('all')}</MenuItem>
                        {(propertyTypes?.data || []).map((propertyType) => (
                            <MenuItem value={propertyType.id} key={propertyType.id}>
                                {propertyType.name}
                            </MenuItem>
                        ))}
                    </ControlledSelect>
                )}

                {filterOptions.projectId.active && !isProjectsLoading && (
                    <ControlledSelect
                        name={`projectId`}
                        label={t('project')}
                        items={projects?.data.map((project) => project.name) || []}
                        size="small"
                    >
                        <MenuItem value={undefined}>{t('all')}</MenuItem>
                        {(projects?.data || []).map((project) => (
                            <MenuItem value={project.id} key={project.id}>
                                {project.name}
                            </MenuItem>
                        ))}
                    </ControlledSelect>
                )}

                {filterOptions.status.active && (
                    <ControlledSelect name={`status`} label={t('status')} size="small">
                        <MenuItem value={undefined}>{t('all')}</MenuItem>
                        {Object.values(PropertyStatus).map((status) => (
                            <MenuItem value={status} key={status}>
                                {t(status)}
                            </MenuItem>
                        ))}
                    </ControlledSelect>
                )}
            </Stack>
        </FormProvider>
    );
};
