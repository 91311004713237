import { Button, IconButton } from '@mui/material';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Page, RemoveModal, SortOrder, StatusCode, Table, useQueryParamsContext } from '../../../shared';
import { useDeleteProject, useProjectList } from '../../hooks/projects.hook';
import { GridCellParams, GridColDef, GridSortDirection } from '@mui/x-data-grid';
import { Link, useNavigate } from 'react-router-dom';
import { DeleteOutline, Edit } from '@mui/icons-material';
import { InUseWarning } from '../../../shared/components/in-use-warning/in-use-warning.component';
import { formatAmount } from '../../../rental-requests/utils';

export const ProjectsPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [projectInUse, setProjectInUse] = useState(false);

    const {
        queryParams: { page, pageSize, sort },
        setPage,
        setPageSize,
        setSort,
    } = useQueryParamsContext('projects', {
        sort: [{ field: 'name', sort: 'asc' as GridSortDirection }],
        filter: {},
    });

    const { data: projects, isLoading } = useProjectList({
        page,
        pageSize,
        sortBy: sort[0]?.field,
        sortOrder: sort[0]?.sort?.toUpperCase() as SortOrder,
    });

    const { mutateAsync: deleteProject } = useDeleteProject();

    const onDelete = useCallback(
        async (id: string) => {
            try {
                await deleteProject(id);
            } catch (e: any) {
                if (e.response.status === StatusCode.CONFLICT) {
                    return setProjectInUse(true);
                }
            }
        },
        [deleteProject],
    );

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: t('projectName'),
            minWidth: 300,
            flex: 1,
        },
        {
            field: 'propertyDiscountOneBedroom',
            headerName: t('propertyDiscountOneBedroom'),
            minWidth: 200,
            flex: 1,
            valueFormatter: (value: number) => `${formatAmount(value)} %`,
            sortable: false,
        },
        {
            field: 'propertyDiscountMultipleBedroomsSingleParent',
            headerName: t('propertyDiscountMultipleBedroomsSingleParent'),
            minWidth: 200,
            flex: 1,
            valueFormatter: (value: number) => `${formatAmount(value)} %`,
            sortable: false,
        },
        {
            field: 'propertyDiscountMultipleBedroomsOther',
            headerName: t('propertyDiscountMultipleBedroomsOther'),
            minWidth: 200,
            flex: 1,
            valueFormatter: (value: number) => `${formatAmount(value)} %`,
            sortable: false,
        },
        {
            field: ' ',
            headerName: '',
            minWidth: 150,
            align: 'right',
            sortable: false,
            renderCell: (params: GridCellParams) => (
                <>
                    <IconButton
                        onClick={(event) => {
                            event.stopPropagation();
                            navigate(`/admin/projects/${params?.row?.id}/edit`);
                        }}
                        size="large"
                    >
                        <Edit />
                    </IconButton>
                    <RemoveModal
                        handleDelete={() => onDelete(params.row.id)}
                        button={
                            <IconButton aria-label="delete" size="large" data-testid={`delete-${params.id}`}>
                                <DeleteOutline />
                            </IconButton>
                        }
                        title={t('projectDeleteWarningTitle')}
                        text={t('projectDeleteWarningText')}
                    />
                </>
            ),
        },
    ];

    return (
        <Page
            title={t('projects')}
            actions={
                <Button component={Link} to="/admin/projects/new" variant="contained">
                    {t('newProject')}
                </Button>
            }
            loading={isLoading}
        >
            <Table
                rows={projects?.data || []}
                columns={columns}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                rowCount={projects?.pagination.size || 0}
                loading={isLoading}
                sortModel={sort}
                onSortModelChange={setSort}
            />

            <InUseWarning
                open={projectInUse}
                onClose={() => setProjectInUse(false)}
                title={t('projectInUseTitle')}
                text={t('projectInUseText')}
            />
        </Page>
    );
};
