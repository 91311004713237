import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlledInput, ControlledSelect, FormGrid, useRegionList } from '../../../shared';
import { MenuItem, Typography } from '@mui/material';

export const PropertyEditAddress: FC = () => {
    const { t } = useTranslation();
    const { data: regions } = useRegionList({ page: 1, pageSize: 1000 });

    return (
        <>
            <Typography variant="subtitle2">{t('address')}</Typography>
            <FormGrid xs={12} md={'auto'}>
                <ControlledInput name="address.street" label={t('street')} required />
                <ControlledInput name="address.number" label={t('number')} required />
                <ControlledInput name="address.box" label={t('box')} />
                <ControlledInput name="address.zip" label={t('zip')} required />
                <ControlledSelect name="address.region" label={t('region')}>
                    {regions?.data?.map((region) => (
                        <MenuItem key={region.id} value={region.name}>
                            {region.name}
                        </MenuItem>
                    ))}
                </ControlledSelect>
                <ControlledInput name="address.city" label={t('city')} required />
                <ControlledInput name="address.country" label={t('country')} required />
            </FormGrid>
        </>
    );
};
