export enum QueryKeys {
    AuthProviders = 'AuthProviders',
    AuthManagers = 'AuthManagers',
    RentalRequests = 'RentalRequests',
    RentalRequest = 'RentalRequest',
    Refusals = 'Refusals',
    Indexations = 'Indexations',
    Indexation = 'Indexation',
    IncomeSettings = 'IncomeSettings',
    CommunicationSettings = 'CommunicationSettings',
    Templates = 'Templates',
    Template = 'Template',
    PropertyType = 'PropertyType',
    PropertyTypes = 'PropertyTypes',
    Property = 'Property',
    Properties = 'Properties',
    Regions = 'Regions',
    UserRoles = 'UserRoles',
    AuthUser = 'AuthUser',
    AllocationTracks = 'AllocationTracks',
    AllocationTrack = 'AllocationTrack',
    AllocationTrackPossibleCandidates = 'AllocationTrackPossibleCandidates',
    AllocationTrackPossibleProperties = 'AllocationTrackPossibleProperties',
    InvitePreview = 'InvitePreview',
    MailMessages = 'MailMessages',
    MailMessage = 'MailMessage',
    Dashboards = 'Dashboards',
    ChartsByDashboard = 'ChartsByDashboard',
    Charts = 'Charts',
    Projects = 'Projects',
    Project = 'Project',
}
