import { Button, Card, CardActions, CardContent, Stack, Typography } from '@mui/material';
import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    ControlledInput,
    ControlledNumberInput,
    FormGrid,
    IParams,
    Page,
    RemoveModal,
    StatusCode,
} from '../../../shared';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeleteProject, useProject, useSaveProject } from '../../hooks/projects.hook';
import { InUseWarning } from '../../../shared/components/in-use-warning/in-use-warning.component';
import { yupResolver } from '@hookform/resolvers/yup';
import { IProjectForm } from '../../models/project.model';
import { useProjectSchema } from '../../validators/project.validator';

export const ProjectEditPage: FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { id } = useParams<keyof IParams>() as IParams;

    const [projectInUse, setProjectInUse] = useState(false);

    const { data: project, isFetching } = useProject(id);
    const { mutateAsync: saveProject, isPending } = useSaveProject();
    const { mutateAsync: deleteProject } = useDeleteProject();

    const form = useForm<IProjectForm>({
        mode: 'all',
        resolver: yupResolver(useProjectSchema()),
    });

    useEffect(() => {
        project
            ? form.reset(project)
            : form.reset({
                  propertyDiscountOneBedroom: 0,
                  propertyDiscountMultipleBedroomsSingleParent: 0,
                  propertyDiscountMultipleBedroomsOther: 0,
              });
    }, [project, form]);

    const onSubmit = useCallback(
        async (project: IProjectForm) => {
            await saveProject({ id, item: { id, ...project } });
            navigate('/admin/projects');
        },
        [id, navigate, saveProject],
    );

    const onDelete = useCallback(async () => {
        try {
            await deleteProject(id);
            navigate('/admin/projects');
        } catch (e: any) {
            if (e.response.status === StatusCode.CONFLICT) {
                return setProjectInUse(true);
            }
        }
    }, [deleteProject, navigate, id]);

    const actions = useMemo(
        () => [
            <Button key={'submit'} variant="contained" onClick={form.handleSubmit(onSubmit)} disabled={isFetching}>
                {t('save')}
            </Button>,
            <Fragment key="delete">
                {id && (
                    <RemoveModal
                        handleDelete={onDelete}
                        button={<Button variant="outlined">{t('delete')}</Button>}
                        title={t('projectDeleteWarningTitle')}
                        text={t('projectDeleteWarningText')}
                    />
                )}
            </Fragment>,
            <Button key={'cancel'} onClick={() => navigate(-1)}>
                {t('cancel')}
            </Button>,
        ],
        [t, form, id, onSubmit, onDelete, navigate, isFetching],
    );

    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    const endAdornment = <Typography sx={{ position: 'relative', left: '-25px', width: 0 }}>%</Typography>;

    return (
        <Page
            onBack={() => navigate('/admin/projects')}
            title={project?.id ? t('updateProject') : t('newProject')}
            loading={isFetching || isPending}
            actions={reversedActions}
        >
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <Card variant="outlined">
                        <CardContent>
                            <FormGrid xs={12} containerProps={{ width: 'fit-content' }}>
                                <ControlledInput name="name" label={t('projectName')} required />
                                <Stack direction="row" spacing={2}>
                                    <ControlledNumberInput
                                        name="propertyDiscountOneBedroom"
                                        label={t('propertyDiscountOneBedroom')}
                                        InputProps={{ endAdornment }}
                                        required
                                    />

                                    <ControlledNumberInput
                                        name="propertyDiscountMultipleBedroomsSingleParent"
                                        label={t('propertyDiscountMultipleBedroomsSingleParent')}
                                        InputProps={{ endAdornment }}
                                        required
                                    />

                                    <ControlledNumberInput
                                        name="propertyDiscountMultipleBedroomsOther"
                                        label={t('propertyDiscountMultipleBedroomsOther')}
                                        InputProps={{ endAdornment }}
                                        required
                                    />
                                </Stack>
                            </FormGrid>
                        </CardContent>

                        <CardActions sx={{ backgroundColor: 'background.default' }}>{actions}</CardActions>
                    </Card>
                </form>
            </FormProvider>

            <InUseWarning
                open={projectInUse}
                onClose={() => setProjectInUse(false)}
                title={t('projectInUseTitle')}
                text={t('projectInUseText')}
            />
        </Page>
    );
};
