import { getYear } from 'date-fns';
import React, { FC, Fragment, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { IParams, IPropertyForm, Page, RemoveModal } from '../../../shared';
import { useDeleteProperty, useProperty, useSaveProperty } from '../../hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { usePropertySchema } from '../../validators';
import { Button, Card, CardActions, CardContent } from '@mui/material';
import { propertyFromFormMapper, propertyToFormMapper } from '../../mapper';
import { PropertyEditAddress } from '../../components/property-edit/property-edit-address.component';
import { PropertyEditOccupation } from '../../components/property-edit/property-edit-occupation.component';
import { PropertyEditRent } from '../../components/property-edit/property-edit-rent.component';
import { PropertyEditExtras } from '../../components/property-edit/property-edit-extras.component';
import { PropertyEditGeneral } from '../../components/property-edit/property-edit-general.component';

export const PropertyEditPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { id } = useParams<keyof IParams>() as IParams;
    const { data: property, isFetching } = useProperty(id);
    const { mutateAsync: saveProperty, isPending } = useSaveProperty();
    const { mutateAsync: deleteProperty } = useDeleteProperty();

    const form = useForm<IPropertyForm>({
        mode: 'all',
        resolver: yupResolver(usePropertySchema()),
    });

    useEffect(() => {
        if (property) {
            form.reset(propertyToFormMapper(property));
        } else {
            form.reset({ rentYear: getYear(new Date()).toString() });
        }
    }, [property, form]);

    const onSubmit = useCallback(
        async (item: IPropertyForm) => {
            const { id: savedId } = await saveProperty({ id, item: propertyFromFormMapper(item) });
            navigate(`/properties/${savedId}`);
        },
        [id, navigate, saveProperty],
    );

    const onDelete = useCallback(async () => {
        await deleteProperty(id);
        navigate('/properties');
    }, [deleteProperty, navigate, id]);

    const actions = useMemo(
        () => [
            <Button key={'submit'} variant="contained" onClick={form.handleSubmit(onSubmit)} disabled={isFetching}>
                {t('save')}
            </Button>,
            <Fragment key="delete">
                {id && (
                    <RemoveModal
                        handleDelete={onDelete}
                        button={<Button variant="outlined">{t('delete')}</Button>}
                        title={t('propertyDeleteWarningTitle')}
                        text={t('propertyDeleteWarningText')}
                    />
                )}
            </Fragment>,
            <Button key={'cancel'} onClick={() => navigate(-1)}>
                {t('cancel')}
            </Button>,
        ],
        [t, form, id, onSubmit, onDelete, navigate, isFetching],
    );
    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    return (
        <Page
            onBack={() => navigate(-1)}
            title={property?.id ? t('updateProperty') : t('newProperty')}
            loading={isFetching || isPending}
            actions={reversedActions}
        >
            <FormProvider {...form}>
                <form
                    onSubmit={form.handleSubmit(onSubmit, (err) => {
                        console.log('err', err);
                    })}
                >
                    <Card variant="outlined">
                        <CardContent sx={{ '.MuiGrid-container': { mb: 2, mt: 0 } }}>
                            <PropertyEditGeneral />
                            <PropertyEditAddress />
                            <PropertyEditOccupation />
                            <PropertyEditRent property={property} />
                            <PropertyEditExtras />
                        </CardContent>
                        <CardActions sx={{ backgroundColor: 'background.default' }} children={actions} />
                    </Card>
                </form>
            </FormProvider>
        </Page>
    );
};
